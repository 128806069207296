import React, { useState, memo, Fragment, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Row, Col, Card, Button } from "react-bootstrap";
import axios from "axios";
import animate from '../../../../assets/event/stock/send_confirm.gif';
import { useMyContext } from "../../../../Context/MyContextProvider";
import Swal from "sweetalert2";
import MobileCheckOut from "../Events/StaticComponents/mobileCheckOut";
import CheckOutTickets from "../TicketModal/CheckOutTickets";
import AgentBookingSummary from "./AgnetBookingComps/AgentBookingSummary";
import EventAccordionAgentPOS from "./AgnetBookingComps/EventAccordionAgentPOS";
import AgentBookingModal from "./AgnetBookingComps/AgentBookingModal";
import DynamicAttendeeForm from "../Events/LandingEvents/OrderComps/DynamicAttendeeForm";
import { checkForDuplicateAttendees, sanitizeData, sanitizeInput, validateAttendeeData } from "../CustomComponents/AttendeeStroreUtils";
import DuplicateAttendeeError from "../Events/LandingEvents/OrderComps/DuplicateAttendeeError";
import { cancelToken, SECONDARY } from "../CustomUtils/Consts";
import EventDatesModal from "../Events/LandingEvents/OrderComps/EventDatesModal";
import { Calendar } from "lucide-react";
const Agent = memo(() => {
    const { api, UserData, isMobile, sendTickets, ErrorAlert,
        authToken, formateTemplateTime, getCurrencySymbol, fetchCategoryData, successAlert } = useMyContext();
    const id = 6;
    const navigate = useNavigate();
    const [isCheckOut, setIsCheckOut] = useState(true);
    const [event, setEvent] = useState([]);

    const [selectedTickets, setSelectedTickets] = useState([]);
    const [bookingHistory, setBookingHistory] = useState([]);
    const [subtotal, setSubTotal] = useState(0);
    const [discount, setDiscount] = useState(0);
    const [ticketCurrency, setTicketCurrency] = useState('₹');
    const [totalTax, setTotalTax] = useState(0);
    const [grandTotal, setGrandTotal] = useState(0);
    const [baseAmount, setBaseAmount] = useState(0);
    const [centralGST, setCentralGST] = useState(0);
    const [stateGST, setStateGST] = useState(0);
    const [number, setNumber] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [disableChoice, setDisableChoice] = useState(false);
    const [ticketSummary, setTicketSummary] = useState([]);
    const [selectedTicketID, setSelectedTicketID] = useState(null);
    const [discountType, setDiscountType] = useState('fixed');
    const [method, setMethod] = useState('Cash');
    const [discountValue, setDiscountValue] = useState();
    const [confirm, setConfirm] = useState(false);
    const [loading, setLoading] = useState(false);
    const [attendee, setAttendee] = useState([]);
    const [resetCounterTrigger, setRsetCounterTrigger] = useState(0)
    const [bookings, setBookings] = useState([])

    //send mail states
    const [masterBookings, setMasterBookings] = useState([]);
    const [normalBookings, setNormalBookings] = useState([]);
    const [categoryData, setCategoryData] = useState();
    const [mainBookings, setMainBookings] = useState([]);
    const [disable, setDisable] = useState(false);
    const [bookingUser, setBookingUser] = useState();

    //attndee state
    const [isAttendeeRequired, setIsAttendeeRequired] = useState(false);
    const [attendeeState, setAttendeeState] = useState(false);
    const [selectedAttendees, setSelectedAttendees] = useState();
    const [allAttendees, setAllAttendees] = useState([]);
    const [attendees, setAttendees] = useState([]);
    const [showDateModal, setShowDateModal] = useState(false);
    const handleShowDateModal = () => setShowDateModal(true);
    const [selectedDate, setSelectedDate] = useState(null);
    const [isAmusment, setIsAmusment] = useState(false);
    const AttendyView = () => {
        setIsAttendeeRequired(categoryData?.categoryData?.attendy_required === 1);
        return categoryData?.categoryData?.attendy_required === 1
    }

    const GetBookings = async () => {
        await axios.get(`${api}agent-bookings/${UserData?.id}`, {
            headers: {
                'Authorization': 'Bearer ' + authToken,
            }
        })
            .then((res) => {
                if (res.data.status) {
                    setBookings(res.data)
                }
            }).catch((err) =>
                console.log(err)
            )
    }
    const getTicketData = async (id) => {
        await axios.get(`${api}event-detail/${id}`, {
            headers: {
                'Authorization': 'Bearer ' + authToken,
            }
        }).then(async (res) => {
            if (res.data.status) {
                let data = res.data.events
                // console.log(data?.category)
                if (data?.category?.title === 'Amusement') {
                    setIsAmusment(true)
                    handleShowDateModal()
                }
                setEvent(data)
                const CData = await fetchCategoryData(data?.category?.id)
                setCategoryData(CData)
            }
        }).catch((err) =>
            console.log(err)
        )
    }

    useEffect(() => {
        if (categoryData) {
            AttendyView()
        }
    }, [categoryData]);

    useEffect(() => {
        GetBookings()
        if (isMobile) {
            setIsCheckOut(true)
        }
    }, [])

    const getTicketCount = (quantity, category, price, id) => {
        if (selectedTicketID && selectedTicketID !== id && quantity > 0) {
            setRsetCounterTrigger(prev => prev + 1);
        }
        setSelectedTicketID(id);
        setSelectedTickets({ category, quantity, price, id });
    };

    useEffect(() => {
        if (selectedTickets?.quantity > 0) {
            setDisable(false)
            let price = selectedTickets?.sale === 'true' ? selectedTickets?.sale_price : selectedTickets?.price;
            const totalPriceForCategory = price * selectedTickets.quantity;
            setSubTotal(totalPriceForCategory);
        } else {
            setDisable(true)
            setSubTotal(0);
            setBaseAmount(0);
            setCentralGST(0);
            setStateGST(0);
            setTotalTax(0);
            setGrandTotal(0);
        }
    }, [selectedTickets]);


    useEffect(() => {
        if (subtotal) {
            // setBaseAmount(subtotal * 10 / 100)
            setCentralGST(baseAmount * 9 / 100)
            setStateGST(baseAmount * 9 / 100)
            setTotalTax((centralGST + stateGST + baseAmount)?.toFixed(2))
        }

        if (((subtotal + totalTax) - discount) > 0) {
            let total = (subtotal + +totalTax) - discount
            setGrandTotal(total?.toFixed(2))
        }
    }, [subtotal, totalTax, discount, baseAmount, centralGST, stateGST]);

    const handleDiscount = () => {
        let disc = 0;
        if (discountValue) {
            if (discountType === 'fixed') {
                disc = discountValue;
                setDiscount(disc)
                setDisableChoice(true)
            } else if (discountType === 'percentage') {
                disc = subtotal * discountValue / 100
                setDiscount(disc)
                setDisableChoice(true)
            }
            console.log(disc)
            setGrandTotal(grandTotal - disc)
        }
    };

    useEffect(() => {
        setDisableChoice(false)
        if (discountValue) {
            setDiscount(0);
        }
    }, [discountValue, discountType]);


    useEffect(() => {
        if (bookingHistory.length > 0) {
            const ticketMap = bookingHistory.reduce((acc, booking) => {
                const ticket = event.tickets?.find(item => item.id === booking.ticket_id);
                if (ticket) {
                    if (!acc[ticket.name]) {
                        acc[ticket.name] = { ...ticket, quantity: 0 };
                    }
                    acc[ticket.name].quantity += 1;
                }
                return acc;
            }, {});

            // Convert the map to an array
            const ticketsData = Object.values(ticketMap);
            setTicketSummary(ticketsData);
        }
    }, [bookingHistory, event.tickets]);



    //model states
    const [showPrintModel, setShowPrintModel] = useState(false);
    const handleClose = () => {
        setShowPrintModel(false)
        setConfirm(false)
        navigate(-1);
    }

    const HandleSendTicket = (data) => {
        // sendTickets(data, 'new')
        let template = isAmusment ? 'Amusement Booking' : 'Booking Confirmation';
        sendTickets(data, 'new', false, template);
    }


    useEffect(() => {
        if (attendee.length !== 0) {
            handleBooking();
        }
    }, [attendee]);


    const [activeKey, setActiveKey] = useState('0');
    useEffect(() => {
        const accordionButton = document.querySelector('.accordion-button');
        if (accordionButton) {
            accordionButton.style.backgroundColor = 'transparent';
        }
    }, []);

    const handleButtonClick = (id) => {
        setAttendeeState(false)
        setAllAttendees([])
        setSelectedTickets(null)
        setRsetCounterTrigger(prev => prev + 1);
        getTicketData(id)
        setSelectedDate('')
        setIsDateSelected(false);
        setActiveKey(null);
    };
    //send mail
    useEffect(() => {
        if (masterBookings.length > 0 || normalBookings) {
            const masterBookingIds = masterBookings.flatMap((data) => data?.booking_id);
            const filteredNormalBookings = normalBookings.filter(
                (booking) => !masterBookingIds.includes(booking?.id)
            );
            const combinedBookings = [...masterBookings, ...filteredNormalBookings];
            setMainBookings(combinedBookings)
        }
    }, [masterBookings, normalBookings]);

    useEffect(() => {
        if (mainBookings?.length > 0) {
            HandleSendMail(mainBookings)
        }
    }, [mainBookings]);

    const getTicketPrice = (category) => {
        let ticket = event?.tickets?.find((item) => item.name === category)
        return ticket?.price
    }

    const HandleSendMail = async (data) => {
        if (data?.length > 0) {
            const Booking = data?.map((item) => {
                // Extracting common fields
                const number = item?.number ?? item?.bookings?.[0]?.number ?? 'Unknown';
                const email = item?.email ?? item?.bookings?.[0]?.email ?? 'Unknown';
                const thumbnail = item?.ticket?.event?.thumbnail ?? item?.bookings?.[0]?.ticket?.event?.thumbnail ?? 'https://smsforyou.biz/ticketcopy.jpg';
                const name = item?.user?.name ?? item?.bookings?.[0]?.user?.name ?? 'Guest';
                const qty = item?.bookings?.length ?? 1;
                const category = item?.ticket?.name ?? item?.bookings?.[0]?.ticket?.name ?? 'General';
                const eventName = item?.ticket?.event?.name ?? item?.bookings?.[0]?.ticket?.event?.name ?? 'Event';
                const eventDate = item?.ticket?.event?.date_range ?? item?.bookings?.[0]?.ticket?.event?.date_range ?? 'TBD';
                const eventTime = item?.ticket?.event?.start_time ?? item?.bookings?.[0]?.ticket?.event?.start_time ?? 'TBD';
                const address = item?.ticket?.event?.address ?? item?.bookings?.[0]?.ticket?.event?.address ?? 'No Address Provided';
                const location = address.replace(/,/g, '|');
                const DateTime = formateTemplateTime(eventDate, eventTime);

                return {
                    email,
                    number, // Assuming you want to include this
                    thumbnail,
                    category,
                    qty,
                    name,
                    price: getTicketPrice(category) * qty?.toFixed(2),
                    eventName,
                    eventDate,
                    eventTime,
                    DateTime,
                    address,
                    location,
                    convenience_fee: totalTax,
                    total: grandTotal
                    // Include any other necessary fields
                };
            });
            if (Booking?.length > 0) {
                sendMail(Booking)
            }
        }
    }
    const sendMail = async (data) => {
        try {
            setDisable(true)
            const res = await axios.post(`${api}booking-mail/${UserData?.id}`, { data }, {
                headers: {
                    'Authorization': 'Bearer ' + authToken,
                }
            });

        } catch (err) {
            console.log(err);
        } finally {
            setDisable(false)
        }
    }


    const getSelectedAttendees = (data) => {
        setAllAttendees(data)
        setDisable(data?.length === selectedTickets?.quantity ? false : true);
        setSelectedAttendees(data?.length)
    }

    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorMessages, setErrorMessages] = useState([]);

    const HandleBookingModel = async () => {
        if (selectedTickets?.quantity > 0) {
            let isAr = AttendyView()
            if (isAr) {
                if (attendeeState) {
                    await HandleAttendeeSubmit()
                } else {
                    setIsAttendeeRequired(false);
                    setDisable(true)
                    setAttendeeState(true)
                }
            } else {
                setShowPrintModel(true);
            }
        } else {
            ErrorAlert('Please Select A Ticket');
        }
    }
    const HandleAttendeeSubmit = async () => {
        if (allAttendees?.some(attendee => {
            const { valid, message } = validateAttendeeData(attendee);
            if (!valid) {
                Swal.fire('Error', message, 'error');
                return true;
            }
            return false;
        })) return;

        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, Save it!',
            cancelButtonText: 'No, cancel!',
        }).then(async (result) => {
            if (result.isConfirmed) {

                try {
                    const formData = new FormData();
                    const atts = sanitizeData(allAttendees)
                    let isDuplicate = checkForDuplicateAttendees(atts, setErrorMessages, setShowErrorModal);
                    if (isDuplicate) {
                        return
                    }
                    //const loader = showLoading('Booking')
                    allAttendees?.forEach((attendee, index) => {
                        Object.keys(attendee).forEach(fieldKey => {
                            if (fieldKey !== 'missingFields') {
                                const fieldValue = attendee[fieldKey];
                                formData.append(`attendees[${index}][${fieldKey}]`, sanitizeInput(fieldValue));
                            }
                        });
                    });

                    // append user id in formData
                    formData.append('user_id', UserData?.id);
                    formData.append('user_name', sanitizeInput(UserData?.name));
                    formData.append('event_name', sanitizeInput(event?.name));
                    formData.append('isAgentBooking', true);
                    const response = await axios.post(`${api}attndy-store`, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                    });
                    if (response.data.status) {
                        // loader.close()
                        successAlert('Success', 'Attendees Saved Successfully')
                        setAttendees(response?.data?.data)
                        setShowPrintModel(true);
                    }
                } catch (error) {
                    console.error('Error submitting form data:', error);
                }
            }
        });
    };
    const handleSubmit = async () => {
        setDisable(true);
        if (!name) {
            ErrorAlert("Name is required");
            return;
        }

        if (!number) {
            ErrorAlert("Mobile number is required");
            return;
        }

        try {
            const res = await axios.post(`${api}chek-email`, {
                'email': email,
                'number': number,
            }, {
                headers: {
                    'Authorization': 'Bearer ' + authToken,
                }
            });

            if (res.data?.exists) {
                if (res.data.is_email_and_mobile_different_users) {
                    ErrorAlert('This number & email is already registered');
                    return false;
                } else {
                    if (res.data?.mobile_exists) {
                        //setBookingUser(res.data.user)
                        handleBooking(res.data?.user);
                    } else if (res.data?.email_exists) {
                        ErrorAlert('This email is already registered');
                    }
                }
            } else {
                handleSignUp();
            }
        } catch (err) {
            ErrorAlert("An error occurred, please try again.");
        }
    };

    const handleSignUp = async () => {
        await axios.post(`${api}create-user`, {
            'name': name,
            'email': email,
            'number': number,
            'password': number,
            'reporting_user': UserData?.id
        }, {
            headers: {
                'Authorization': 'Bearer ' + authToken,
            }
        }).then((res) => {
            if (res.data.status) {
                handleBooking(res.data.user)
            }
        }).catch((err) =>
            console.log(err)
        )
    }
    const handleBooking = async (createdUser) => {
        const validTickets = selectedTickets?.quantity > 0;
        let url;
        let masterUrl;
        if (isAmusment) {
            url = `${api}amusement-agent-book-ticket/${id}`
            masterUrl = `${api}amusement-agent-master-booking/${createdUser?.id}`
        } else {
            url = `${api}agent-book-ticket/${id}`
            masterUrl = `${api}agent-master-booking/${createdUser?.id}`
        }
        if (validTickets) {
            const formData = new FormData();
            attendees?.forEach((attendee, index) => {
                Object.entries(attendee).forEach(([fieldKey, fieldValue]) => {
                    formData.append(`attendees[${index}][${fieldKey}]`, fieldValue);
                });
            });
            const requestData = {
                agent_id: UserData?.id,
                user_id: createdUser?.id,
                number: createdUser?.number,
                email: createdUser?.email,
                base_amount: subtotal,
                type: event?.event_type,
                name: createdUser?.name,
                booking_date: selectedDate,
                payment_method: method,
                amount: grandTotal,
                discount: discount,
                tickets: selectedTickets
            };

            Object.entries(requestData).forEach(([key, value]) => {
                formData.append(key, value);
            });

            // Handle selectedTickets object
            Object.entries(selectedTickets || {}).forEach(([ticketKey, ticketValue]) => {
                formData.append(`tickets[${ticketKey}]`, ticketValue);
            });

            try {
                const res = await axios.post(url, formData, {
                    headers: {
                        'Authorization': 'Bearer ' + authToken,
                    },
                    cancelToken: cancelToken
                });
                if (res.data.status) {
                    setBookingHistory(res.data?.bookings);
                    const bookings = res.data?.bookings;
                    const bookingIds = bookings.map(booking => booking?.id);
                    setNormalBookings(prevNormalBookings => [...prevNormalBookings, ...bookings]);
                    if (bookings.length > 1) {
                        const masterRes = await axios.post(masterUrl, {
                            agent_id: UserData?.id,
                            user_id: createdUser?.id,
                            bookingIds
                        }, {
                            headers: {
                                'Authorization': 'Bearer ' + authToken,
                            }
                        });
                        if (masterRes.data.status) {
                            setLoading(false);
                            setConfirm(true);
                            const master = masterRes.data.booking;
                            setMasterBookings(prevMasterBookings => [...prevMasterBookings, master]);
                            HandleSendTicket(masterRes.data?.booking)
                        }
                    } else {
                        HandleSendTicket(bookings.find((item) => item?.id === bookings[0]?.id))
                        setLoading(false)
                        setConfirm(true);
                    }
                    setIsCheckOut(false);
                }
            } catch (err) {
                console.log(err);
            } finally {
                setDisable(false);
            }
        } else {
            ErrorAlert('Please Select A Ticket');
        }
    };
    const [isDateSelected, setIsDateSelected] = useState(false);

    const HandleDate = async () => {
        setIsDateSelected(true);
        setShowDateModal(false);
    }
    return (
        <Fragment>
            <EventDatesModal
                show={showDateModal}
                setSelectedDate={setSelectedDate}
                selectedDate={selectedDate}
                dateRange={event?.date_range}
                setShow={setShowDateModal}
                handleSave={HandleDate}
            />
            <DuplicateAttendeeError
                errorMessages={errorMessages}
                showErrorModal={showErrorModal}
                setShowErrorModal={setShowErrorModal}
            />
            <AgentBookingModal
                name={name}
                showPrintModel={showPrintModel}
                handleClose={handleClose}
                confirm={confirm}
                attendee={attendee}
                disabled={disable}
                loading={loading}
                animate={animate}
                setName={setName}
                number={number}
                setNumber={setNumber}
                email={email}
                setEmail={setEmail}
                handleSubmit={handleSubmit}
                setMethod={setMethod}
            />

            {(isMobile && isCheckOut) &&
                <MobileCheckOut
                    disable={disable}
                    ticketCurrency={ticketCurrency}
                    handlePayment={HandleBookingModel}
                    grandTotal={grandTotal}
                    isAttendeeRequired={isAttendeeRequired}
                    attendeeState={attendeeState}
                    attendees={selectedAttendees}
                    quantity={selectedTickets?.quantity}
                />
            }
            <Row>
                <Col lg="12">
                    <EventAccordionAgentPOS
                        handleButtonClick={handleButtonClick}
                        activeKey={activeKey}
                        setActiveKey={setActiveKey}
                    />
                </Col>
            </Row>
            <Row>
                {attendeeState ? (
                    <DynamicAttendeeForm
                        showAttendeeSuggetion={event?.offline_att_sug === 0}
                        isAgent={true}
                        getAttendees={getSelectedAttendees}
                        category_id={categoryData?.categoryData?.id}
                        setDisable={setDisable}
                        disable={disable}
                        AttendyView={AttendyView}
                        event={event}
                        setAttendees={setAttendees}
                        apiData={categoryData?.customFieldsData}
                        setAttendeeState={setAttendeeState}
                        selectedTickets={selectedTickets}
                        quantity={selectedTickets?.quantity}
                    />
                ) : (event?.category?.title !== 'Amusement' || (event?.category?.title === 'Amusement' && isDateSelected)) && (
                    <Col lg="8">
                        <Card>
                            <Card.Header className="py-3">
                                <div className="row align-items-center">
                                    <div className="col-md-6 col-12">
                                        <h5 className="mb-0">{event?.name}</h5>
                                    </div>
                                    {selectedDate && (
                                        <div className="col-md-6 col-12 text-md-end text-start mt-2 mt-md-0">
                                            <span className="text-black d-flex align-items-center gap-1 justify-content-md-end">
                                                <Button className="p-0 m-0 d-flex" variant="link" onClick={handleShowDateModal}>
                                                    <Calendar size={16} color={SECONDARY} />
                                                </Button>
                                                : {selectedDate}
                                            </span>
                                        </div>
                                    )}
                                </div>
                            </Card.Header>
                            {event &&
                                <Card.Body className="p-0">
                                    <CheckOutTickets
                                        loading={loading}
                                        event={event}
                                        isMobile={isMobile}
                                        resetCounterTrigger={resetCounterTrigger}
                                        getTicketCount={getTicketCount}
                                        selectedTickets={selectedTickets}
                                        getCurrencySymbol={getCurrencySymbol}
                                    />
                                </Card.Body>
                            }
                        </Card>
                    </Col>
                )}
                {(event?.category?.title !== 'Amusement' || (event?.category?.title === 'Amusement' && isDateSelected)) && (
                    <Col lg="4">
                        <AgentBookingSummary
                            disabled={disable}
                            bookings={bookings}
                            ticketCurrency={ticketCurrency}
                            subtotal={subtotal}
                            discount={discount}
                            baseAmount={baseAmount}
                            centralGST={centralGST}
                            totalTax={totalTax}
                            discountType={discountType}
                            setDiscountType={setDiscountType}
                            discountValue={discountValue}
                            setDiscountValue={setDiscountValue}
                            disableChoice={disableChoice}
                            handleDiscount={handleDiscount}
                            grandTotal={grandTotal}
                            HandleBookingModel={HandleBookingModel}
                            isMobile={isMobile}
                            isAttendeeRequired={isAttendeeRequired}
                        />
                    </Col>
                )}
            </Row>
        </Fragment >
    );
});

Agent.displayName = "Agent";
export default Agent;
