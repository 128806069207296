import React, { useEffect, useState } from 'react'
import { Button, Col, Form, Image, Modal, Row } from 'react-bootstrap'
import { Loader2, MailIcon, MessageSquare } from 'lucide-react';
import { FaWhatsapp } from 'react-icons/fa';
import { useMyContext } from '../../../../../Context/MyContextProvider';
import axios from 'axios';
import { cancelToken } from '../../CustomUtils/Consts';

const AgentBookingModal = (props) => {
    const { api, authToken, loader } = useMyContext();
    const { showPrintModel, handleClose, confirm, attendee, disabled, loading, setName, name, number, setNumber, setEmail, handleSubmit, setMethod, email } = props;
    const [isExist, setIsExist] = useState(false);
    const [checkingUser, setCheckingUser] = useState(false);
    const isFormValid = () => {
        // If number is not entered, form is invalid
        if (!number || (number.length !== 10 && number.length !== 12)) return false;

        // If API call hasn't been made yet (user not found)
        if (!isExist) {
            // Check if manual entries are filled
            return Boolean(name && email && number);
        }

        // If user found via API, form is valid as fields will be auto-filled
        return true;
    };
    const HandleCheckUser = async (number) => {
        setCheckingUser(true);
        try {
            const url = `${api}user-form-number/${number}`;
            const response = await axios.get(url, {
                headers: {
                    Authorization: `Bearer ${authToken}`,
                },
                cancelToken: cancelToken
            });

            setIsExist(response.data.status)
            if (response.data.status) {
                setName(response.data?.user?.name);
                setEmail(response.data?.user?.email);
            }
        } catch (error) {
            console.error("Error fetching user:", error);
            return null;
        } finally {
            setCheckingUser(false);
        }
    };

    useEffect(() => {
        if (number && (number?.length === 10 || number?.length === 12)) {
            HandleCheckUser(number)
        } else {
            setName("");
            setEmail("");
            setIsExist(false);
        }
    }, [number]);
    return (
        <Modal show={showPrintModel} onHide={() => handleClose()} centered>
            <Modal.Header closeButton={!disabled}>
                <Modal.Title className="text-center w-100">{confirm ? 'Thank You For Your Booking!' : 'User Detail For This Booking'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {loading ? (
                    <div className="d-flex flex-column align-items-center justify-content-center py-4">
                        <Image src={loader} width={150} />
                    </div>
                ) : confirm ? (
                    <div className="d-flex flex-column justify-content-center py-3">
                        <span className="text-center">
                            {/* <Image src={animate} width={150} /> */}
                            <Image src={loader} width={150} />
                        </span>
                        <h3 className="text-center">Booking Confirmed</h3>
                        <p className="text-center py-3">Ticket sent to {attendee?.name} on Email/WhatsApp/SMS.</p>

                        <div className="text-center">
                            <Button className="border rounded-pill w-50" disabled={disabled} onClick={() => handleClose()}>{(loading || disabled) ? 'Please Wait' : 'Close'}</Button>
                        </div>
                    </div>
                ) : (
                    <div className="">
                        <Row className="d-flex justify-content-between">
                            <Col sm="12" md="12" className="form-group">
                                <Form.Control
                                    type="number"
                                    id="Phone_NO"
                                    placeholder="Enter Phone Number"
                                    onChange={(e) => setNumber(e.target.value)}
                                    onInput={(e) => {
                                        if (e.target.value.length > 12) e.target.value = e.target.value.slice(0, 10);
                                    }}
                                />
                                {!number && (
                                    <div className="text-info mt-2 small">
                                        Please enter mobile number to check existing user details
                                    </div>
                                )}
                                {checkingUser && (
                                    <div className="text-primary mt-2 small d-flex align-items-center gap-2">
                                        <Loader2 size={16} className="animate-spin" />
                                        Checking user details...
                                    </div>
                                )}
                            </Col>
                            {!checkingUser && number && (number.length === 10 || number.length === 12) && (
                                <>
                                    <Col sm="12" md="12" className="form-group">
                                        <Form.Control
                                            type="text"
                                            id="firstName"
                                            placeholder="Enter Name"
                                            value={name}
                                            onChange={(e) => setName(e.target.value)}
                                            maxLength={50}
                                            disabled={isExist}
                                        />
                                    </Col>
                                    <Col sm="12" md="12" className="form-group">
                                        <Form.Control
                                            type="email"
                                            id="Emailid"
                                            placeholder="Enter Email"
                                            disabled={isExist}
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                        />
                                    </Col>
                                </>
                            )}

                            <Col sm="12" md="12" className="form-group">
                                <div className="text-center text-secondary pb-3">
                                    <Form.Label className="form-check-label ms-1" htmlFor="aggrement-hopeui">
                                        <span className="me-2">
                                            Ticket will be sent to {name || 'User'} on
                                        </span>
                                        <span>
                                            <MailIcon size={16} /> / <FaWhatsapp size={16} /> / <MessageSquare size={16} />
                                        </span>
                                    </Form.Label>
                                </div>
                            </Col>
                            <Col sm="4" md="4" className="form-group">
                                <div className="form-radio form-check">
                                    <Form.Check.Input
                                        type="radio"
                                        id="customRadio8"
                                        name="payment"
                                        className="me-2"
                                        value={'Cash'}
                                        onChange={(e) => setMethod(e.target.value)}
                                        defaultChecked
                                    />
                                    <Form.Label
                                        className="custom-control-label"
                                        htmlFor="customRadio8"
                                    >
                                        {" "}
                                        Cash
                                    </Form.Label>
                                </div>
                            </Col>
                            <Col sm="4" md="4" className="form-group">
                                <div className="form-radio form-check">
                                    <Form.Check.Input
                                        type="radio"
                                        id="customRadio8"
                                        name="payment"
                                        className="me-2"
                                        value={'UPI'}
                                        onChange={(e) => setMethod(e.target.value)}

                                    />
                                    <Form.Label
                                        className="custom-control-label"
                                        htmlFor="customRadio8"
                                    >
                                        {" "}
                                        UPI
                                    </Form.Label>
                                </div>
                            </Col>
                            <Col sm="4" md="4" className="form-group">
                                <div className="form-radio form-check">
                                    <Form.Check.Input
                                        type="radio"
                                        id="customRadio8"
                                        name="payment"
                                        className="me-2"
                                        value={'Net Banking'}
                                        onChange={(e) => setMethod(e.target.value)}
                                    />
                                    <Form.Label
                                        className="custom-control-label"
                                        htmlFor="customRadio8"
                                    >
                                        {" "}
                                        Net Banking
                                    </Form.Label>
                                </div>
                            </Col>
                            <Col sm="12" md="12" className="form-group">
                                <div className="d-flex justify-content-center pb-3">
                                    <Button
                                        type="button"
                                        className="btn btn-primary d-flex align-items-center justify-content-center gap-2"
                                        onClick={() => handleSubmit()}
                                        disabled={loading || disabled || !isFormValid()}
                                    >
                                        {loading && <Loader2 size={20} className="animate-spin" />}
                                        {loading ? 'Sending Tickets' : 'Submit'}
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </div>
                )}
            </Modal.Body>
        </Modal>
    )
}

export default AgentBookingModal
