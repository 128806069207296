import { Link } from "react-router-dom";
import { Tooltip, OverlayTrigger, ButtonGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { faMessage, faEnvelope } from "@fortawesome/free-regular-svg-icons";

const icons = [
  { icon: faWhatsapp, color: "text-success", tooltip: "WhatsApp" },
  { icon: faMessage, color: "text-warning", tooltip: "SMS" },
  { icon: faEnvelope, color: "text-danger", tooltip: "Email" },
];

const TicketActions = () => {
  return (
    <div className="text-center d-flex align-items-center gap-2">
      <Link to="">Send Tickets</Link>
      <span>|</span>
      <ButtonGroup className="d-flex align-items-center">
        {icons.map(({ icon, color, tooltip }, index) => (
          <OverlayTrigger key={index} placement="top" overlay={<Tooltip>{tooltip}</Tooltip>}>
            <Link to="#" className={`${color} px-2 d-flex align-items-center`}>
              <FontAwesomeIcon icon={icon} />
            </Link>
          </OverlayTrigger>
        ))}
      </ButtonGroup>
    </div>
  );
};

export default TicketActions;