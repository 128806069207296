import React, { memo, Fragment, useState, useEffect, useCallback } from "react";
import { Row, Col, Card, Modal, Button } from "react-bootstrap";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { useMyContext } from "../../../../Context/MyContextProvider";
import Swal from "sweetalert2";
import { Phone, PlusIcon, ScanLine, Settings, ShoppingCart, Store, Trash2, UsersRound } from "lucide-react";
import CustomDataTable from "../Wallet/CustomDataTable";
import { CustomTooltip } from "../CustomUtils/CustomTooltip";
import CommonListing from "../CustomUtils/CommonListing";
import TableWithSearch from "../CustomUtils/TableWithSearch";

const Users = memo(() => {
  const { api, formatDateTime, successAlert, userRole, authToken, ErrorAlert } = useMyContext();
  const navigate = useNavigate();
  const [users, setUsers] = useState([])
  const [loading, setLoading] = useState(true);

  const GetUsers = useCallback(async () => {
    setLoading(true);
    try {
      const res = await axios.get(`${api}users`, {
        headers: {
          Authorization: "Bearer " + authToken,
        },
      });
      if (res.data.status) {
        setUsers(res.data.allData);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }, [authToken, api]);

  useEffect(() => {
    GetUsers();
  }, [GetUsers]);




  const AssignCredit = useCallback((id) => {
    navigate(`manage/${id}`);
  }, [navigate]); // Ensures function doesn't recreate on every render

  const HandleDelete = useCallback(async (id) => {
    if (!id) return;

    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
    });

    if (result.isConfirmed) {
      try {
        const res = await axios.delete(`${api}user-delete/${id}`, {
          headers: {
            Authorization: "Bearer " + authToken,
          },
        });
        if (res.data?.status) {
          GetUsers();
          successAlert("Success", "User Deleted successfully.");
        }
      } catch (err) {
        ErrorAlert(err.response?.data?.message || "An error occurred");
      }
    }
  }, [authToken, ErrorAlert, GetUsers, successAlert, api]);

  const [show, setShow] = useState(false);
  const HandleNaviGate = () => {
    if (userRole === 'Organizer') {
      setShow(true)
    }
    else {
      navigate('new')
    }
  }

  const HandleQueryParam = (param) => {
    if (param) {
      setShow(false)
      navigate(`/dashboard/users/new?type=${param}`);
    }
  }
  const columns = [
    {
      dataField: 'id',
      text: '#',
      formatter: (cell, row, rowIndex) => rowIndex + 1,
      headerAlign: 'center',
      align: 'center',
      sort: true
    },
    {
      dataField: 'name',
      text: 'Name',
      headerAlign: 'center',
      align: 'center',
      sort: true
    },
    {
      dataField: 'contact',
      text: 'Contact',
      headerAlign: 'center',
      align: 'center',
      sort: true
    },
    {
      dataField: 'email',
      text: 'Email',
      headerAlign: 'center',
      align: 'center',
      sort: true
    },
    {
      dataField: 'authentication',
      text: 'Auth',
      formatter: (cell) => parseInt(cell) === 1 ? "Password" : "OTP",
      headerAlign: 'center',
      align: 'center',
      sort: true
    },
    {
      dataField: 'role_name',
      text: 'Role',
      formatter: (cell) => {
        const badgeClass = {
          'Admin': 'bg-info',
          'Organizer': 'bg-primary',
          'User': 'bg-warning',
          'Agent': 'bg-danger',
          'Support Executive': 'bg-success'
        }[cell] || 'bg-secondary';
        return <span className={`badge p-2 fw-normal ls-1 ${badgeClass} w-100`}>{cell}</span>;
      },
      headerAlign: 'center',
      align: 'center',
      sort: true
    },
    {
      dataField: 'organisation',
      text: 'Organisation',
      headerAlign: 'center',
      align: 'center',
      sort: true
    },
    {
      dataField: 'reporting_user',
      text: 'Account Manager',
      headerAlign: 'center',
      align: 'center',
      sort: true
    },
    {
      dataField: 'status',
      text: 'Status',
      formatter: (cell) => {
        const circleClass = cell === "0" ? 'bg-danger' : 'bg-success';
        const statusText = cell === "0" ? 'Deactive' : 'Active';
        return <span className={`d-inline-block rounded-circle ${circleClass}`} style={{ width: '12px', height: '12px' }} title={statusText} />;
      },
      headerAlign: 'center',
      align: 'center',
      sort: true
    },
    {
      dataField: 'created_at',
      text: 'Created At',
      formatter: (cell) => formatDateTime(cell),
      headerAlign: 'center',
      align: 'center',
      sort: true
    },
    {
      dataField: "action",
      text: "Action",
      formatter: (cell, row) => {
        const isDisabled = row?.is_deleted || row?.status === "1";
        const actions = [
          {
            tooltip: "Manage User",
            onClick: () => AssignCredit(row.id),
            icon: <Settings size={16} />,
            variant: "primary"
          },
          {
            tooltip: "Delete User",
            onClick: () => HandleDelete(row.id),
            icon: <Trash2 size={16} />,
            variant: "danger"
          }
        ];

        return (
          <div className="d-flex gap-2 justify-content-center">
            {actions.map((action, index) => (
              <CustomTooltip key={index} text={action.tooltip}>
                <Button
                  variant={action.variant}
                  className="btn-sm btn-icon"
                  onClick={action.onClick}
                  disabled={isDisabled}
                >
                  {action.icon}
                </Button>
              </CustomTooltip>
            ))}
          </div>
        );
      },
      headerAlign: "center",
      align: "center"
    }

  ];
  return (
    <Fragment>
      {/* print model  */}
      <Modal show={show} onHide={() => setShow(false)} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Select User Type</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col lg="4" className="mb-2">
              <Card className="m-0 border border-primary" style={{ cursor: 'pointer' }} onClick={() => HandleQueryParam('POS')}>
                <Card.Body>
                  <div className="d-flex gap-3 align-items-center">
                    <Link className="btn btn-sm btn-icon text-primary" data-bs-toggle="tooltip" title="Edit User" to="#">
                      <i className="icon">
                        <ShoppingCart />
                      </i>
                    </Link>
                    <span className="text-primary">
                      POS
                    </span>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col lg="4" className="mb-2">
              <Card className="m-0 border border-primary" style={{ cursor: 'pointer' }} onClick={() => HandleQueryParam('Agent')}>
                <Card.Body>
                  <div className="d-flex gap-3 align-items-center">
                    <Link className="btn btn-sm btn-icon text-primary" data-bs-toggle="tooltip" title="Edit User" to="#">
                      <i className="icon">
                        <UsersRound />
                      </i>
                    </Link>
                    <span className="text-primary">
                      Agent
                    </span>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col lg="4" className="mb-2">
              <Card className="m-0 border border-primary" style={{ cursor: 'pointer' }} onClick={() => HandleQueryParam('Scanner')}>
                <Card.Body>
                  <div className="d-flex gap-3 align-items-center">
                    <Link className="btn btn-sm btn-icon text-primary" data-bs-toggle="tooltip" title="Edit User" to="#">
                      <i className="icon">
                        <ScanLine />
                      </i>
                    </Link>
                    <span className="text-primary">
                      Scanner
                    </span>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col lg="4" className="mb-2">
              <Card className="m-0 border border-primary" style={{ cursor: 'pointer' }} onClick={() => HandleQueryParam('Support-Executive')}>
                <Card.Body>
                  <div className="d-flex gap-3 align-items-center">
                    <Link className="btn btn-sm btn-icon text-primary" data-bs-toggle="tooltip" title="Edit User" to="#">
                      <i className="icon">
                        <Phone />
                      </i>
                    </Link>
                    <span className="text-primary">
                      Support Executive
                    </span>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col lg="4">
              <Card className="m-0 border border-primary" style={{ cursor: 'pointer' }} onClick={() => HandleQueryParam('Shop-Keeper')}>
                <Card.Body>
                  <div className="d-flex gap-3 align-items-center">
                    <Link className="btn btn-sm btn-icon text-primary" data-bs-toggle="tooltip" title="Edit User" to="#">
                      <i className="icon">
                        <Store />
                      </i>
                    </Link>
                    <span className="text-primary">
                      Shop Keeper
                    </span>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>

        </Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
      </Modal>
      {/* print model end */}

      <Row>
        <Col sm="12">
          <Card>
            <Card.Header className="d-flex justify-content-between">
              <div className="header-title">
                <h4 className="card-title">Users</h4>
              </div>
              <div className="button">
                <h4 className="card-title">
                  <Link onClick={() => HandleNaviGate()}>
                    <Button className="gap-1 hvr-icon-sink-away hvr-curl-top-right border-0 d-flex align-content-center justify-content-center">
                      New User
                      <PlusIcon size={22} />
                    </Button>
                  </Link>
                </h4>
              </div>
            </Card.Header>
            <Card.Body className="px-0">
              <TableWithSearch
                title="Users"
                data={users}
                columns={columns}
                loading={loading}
                keyField="id"
                searchPlaceholder="Search users..."
                pageOptions={[10, 25, 50, 100]}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
});

Users.displayName = "Users";
export default Users;
