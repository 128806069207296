import React from 'react';
import { Card, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import CountUp from 'react-countup';

const CountCard = ({ title, amount, note, hideCurrency }) => {
    return (

        <Card className="card-block card-stretch card-height">
            <Card.Body className='py-2'>
                <div className="mb-2 d-flex justify-content-between align-items-center">
                    <span className="text-dark">{title}</span>
                    <Link
                        className="badge rounded-pill bg-primary-subtle"
                        to="#javascript:void(0);"
                    >
                        Total
                    </Link>
                </div>
                <h2 className="counter">
                    {hideCurrency ? '' : '₹'}
                    <CountUp
                        start={0}
                        end={amount || 0}
                        duration={3}
                        useEasing={true}
                        separator=","
                    />
                </h2>
                {note !== '' && <small>{note}</small>}
            </Card.Body>
        </Card>
    );
};

export default CountCard;
