import React, { useState, memo, useEffect } from "react";
import { Row, Col, Image, Nav, Tab, Table, Card, Container } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import ShareOffcanvas from "../../../../../components/partials/components/shareOffcanvasNew";
import partyImage from "../../../../../assets/modules/e-commerce/images/product/party3.jpg";
import axios from "axios";
import { useMyContext } from "../../../../../Context/MyContextProvider";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import CommonPricingComp from "../../TicketModal/CommonPricingComp";
import MobileBookNowButton from "./MobileBookNowButton";
import CommonMobileTopBar from "../../CustomUtils/BookingUtils/CommonMobileTopBar";
import DetailsTab from "./EventDetailTabComps/DetailsTab";
import TabContents from "./EventDetailTabComps/EventTabContents";
import MetaData from "../../CustomUtils/MetaData";
import { MapPin, ShoppingCart } from "lucide-react";
import EventDatesModal from "./OrderComps/EventDatesModal";
import LoginModel from "../../Auth/LoginModel";
const EventDetail = memo(() => {
    const { api, UserData, isMobile, authToken, successAlert, formatDateRange, convertTo12HourFormat, systemSetting } = useMyContext();
    const { id } = useParams();
    const navigate = useNavigate();
    const [event, setEvent] = useState([]);

    const [modelShow, setModelShow] = useState(false);
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };
    useEffect(() => {
        scrollToTop()
        axios.get(`${api}event-detail/${id}`, {
            headers: {
                'Authorization': 'Bearer ' + authToken,
            }
        }).then((res) => {
            if (res.data.status) {
                setEvent(res.data.events)
            }
        }).catch((err) =>
            console.log(err)
        )
    }, []);


    const handleBooking = async (status) => {
        const isLoggedIn = Object?.keys(UserData).length > 0;
    
        if (status || isLoggedIn) {
            if (event?.category?.title === 'Amusement') {
                if (selectedDate) {
                    navigate('/events/' + id + '/process', { state: { selectedDate } });
                } else {
                    handleShowDateModal();
                    return;
                }
            } else {
                navigate('/events/' + id + '/process');
            }
    
            // Show success alert only if the user just logged in
            if (status && !isLoggedIn) {
                successAlert('Success', 'Login Successfully');
            }
        } else {
            setModelShow(true);
        }
    };
    
    const disable = event?.booking_not_start || event?.booking_close
    const formatPhoneNumber = (number) => {
        let cleanNumber = number?.replace(/\D/g, '');

        if (cleanNumber?.length > 10) {
            return `+${cleanNumber?.slice(0, -10)}-${cleanNumber?.slice(-10, -6)}-${cleanNumber?.slice(-6, -4)}-${cleanNumber?.slice(-4)}`;
        } else {
            return `${cleanNumber?.slice(0, 4)}-${cleanNumber?.slice(4, 6)}-${cleanNumber?.slice(6)}`;
        }
    };
    const formattedNumber = formatPhoneNumber(systemSetting?.missed_call_no);

    // make state for showDateModal
    const [showDateModal, setShowDateModal] = useState(false);
    const handleShowDateModal = () => setShowDateModal(true);
    const handleCloseDateModal = () => setShowDateModal(false);
    const [selectedDate, setSelectedDate] = useState(null);
    return (
        <Container style={{ marginTop: !isMobile && '6rem' }}>
            <MetaData event={event} />
            {/* //login model */}
            <LoginModel
                modelShow={modelShow}
                handleClose={() => setModelShow(false)}
                api={api}
                onLoginSuccess={(status) => {
                    handleBooking(status)
                }}
            />
            {/* //login model end*/}
            <Row>
                <Col lg={12}>
                    <CommonMobileTopBar isMobile={isMobile} />
                    <MobileBookNowButton
                        isMobile={isMobile}
                        disable={disable}
                        handleBooking={handleBooking}
                    />
                    <EventDatesModal
                        show={showDateModal}
                        setSelectedDate={setSelectedDate}
                        selectedDate={selectedDate}
                        dateRange={event?.date_range}
                        setShow={setShowDateModal}
                        handleSave={handleBooking}
                    />
                    <hr />
                    <Card className="mt-5">
                        <Card.Body>
                            <Row>
                                <Col lg="12">
                                    <Row className="align-items-center">
                                        <Col lg="5">
                                            <div className="product-vertical-slider">
                                                <div className="d-flex justify-content-center">
                                                    <Image
                                                        width={isMobile ? 250 : 400}
                                                        src={event?.thumbnail || partyImage}
                                                        alt="product-details"
                                                        className="img-fluid iq-product-img hover-media rounded-5"
                                                    />
                                                </div>
                                            </div>
                                            <div className="contacts text-center my-3">
                                                <p>
                                                    <i className="fa-brands fa-whatsapp text-success me-2" />
                                                    Say <strong>Hii</strong> to {formattedNumber}
                                                </p>
                                                <p>
                                                    <FontAwesomeIcon icon={faPhone} className="me-2" />
                                                    Give us a Missed call: {formattedNumber}
                                                </p>
                                            </div>
                                        </Col>
                                        <Col lg="7" className="mt-4 mt-lg-0">
                                            <div className="border-bottom">
                                                <div className="d-flex flex-column align-content-between justify-items-center mb-3">
                                                    <div className="d-flex justify-content-between mb-2">
                                                        <h4 className="mb-0">{event?.name}</h4>
                                                        <div className="d-flex justify-content-end ">
                                                            <ShareOffcanvas share={true} />
                                                        </div>
                                                    </div>

                                                </div>
                                                {event?.booking_not_start ?
                                                    <p className="text-danger">Booking Not Started Yet</p> :
                                                    event?.booking_close ?
                                                        <p className="text-danger">Booking Closed</p> :
                                                        <div className="d-flex align-items-center">
                                                            <div className="table-responsive  w-100">
                                                                <Table responsive striped className="mb-0" role="grid">
                                                                    <thead>
                                                                        <tr>
                                                                            <th className="text-black h5">Ticket Category</th>
                                                                            <th className="text-black h5">Price</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {
                                                                            event?.tickets?.map((item, index) => {
                                                                                return (
                                                                                    <tr key={index}>
                                                                                        <td className="text-black">{item.name}</td>
                                                                                        <td className="text-black h5">
                                                                                            <CommonPricingComp currency={item.currency} price={item.price} isSale={item?.sale} salePrice={item?.sale_price} /></td>
                                                                                    </tr>
                                                                                )
                                                                            })
                                                                        }
                                                                    </tbody>
                                                                </Table>
                                                            </div>
                                                        </div>
                                                }
                                            </div>
                                            <div className="d-flex gap-2 border-bottom">
                                                <p className="py-4 mb-0">
                                                    <MapPin />
                                                </p>
                                                <p className="py-4 mb-0">
                                                    {event?.address}
                                                </p>
                                            </div>
                                            <div className="d-flex flex-column py-4">
                                                <div className="d-flex align-items-center mb-3">
                                                    <span className="text-dark">Event Date:</span>
                                                    <span className="text-primary  ms-2">
                                                        {formatDateRange(event?.date_range)}
                                                    </span>
                                                </div>
                                                <div className="d-flex align-items-center mb-3">
                                                    <span className="text-dark">Starts At:</span>
                                                    <span className="text-primary  ms-2">{convertTo12HourFormat(event?.start_time)}</span>
                                                </div>
                                            </div>
                                            {
                                                !isMobile &&
                                                <div>
                                                    <div className={`d-flex py-4 flex-wrap gap-4 ${disable && 'opacity-50'}`}
                                                        onClick={() => !disable && handleBooking()}>
                                                        <Link
                                                            disabled={disable}
                                                            className="btn btn-warning d-flex align-items-center gap-2"
                                                        >
                                                            <span className="btn-inner d-flex ">
                                                                <ShoppingCart />
                                                            </span>
                                                            Book Now
                                                        </Link>
                                                    </div>
                                                </div>
                                            }

                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                    <Card>
                        <Card.Body>
                            <Tab.Container defaultActiveKey="first">
                                <Nav className="tab-bottom-bordered">
                                    <DetailsTab />
                                </Nav>
                                <Tab.Content className="mt-4 iq-tab-fade-up" id="nav-tabContent">
                                    <TabContents event={event} />
                                </Tab.Content>
                            </Tab.Container>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
});
EventDetail.displayName = "EventDetail";
export default EventDetail;
